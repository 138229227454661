<template>
  <div class="dialog-container" v-if="data.paymentQRCode">
    <el-dialog
      title="支付"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      class="order-pay-wrapper"
      width="70%"
    >
      <el-container>
        <el-tabs class="taps-card" style="with: 100%">
          <el-tab-pane label="微信" class="pay-wrap-wrapper">
            <div class="order-pay-content">
              <div class="pay-wrap">
                <div class="pay-title">{{ data.venueName }}</div>
                <div class="pay-detail">订单详情</div>
                <div class="pay-detail-line"></div>
                <div class="content">
                  <div class="item">
                    <div>订单号</div>
                    <div class="info">{{ data.orderNo }}</div>
                  </div>
                  <div class="item">
                    <div>下单时间</div>
                    <div class="info">{{ data.orderTime }}</div>
                  </div>
                  <div class="item">
                    <div>客户姓名</div>
                    <div class="info">{{ data.customerName }}</div>
                  </div>
                  <div class="item">
                    <div>收银员</div>
                    <div class="info">{{ data.cashier }}</div>
                  </div>
                </div>
                <div class="line-wrapper">
                  <div class="pay-statement">消费明细</div>
                  <div class="pay-statement-line"></div>
                </div>
                <p style="text-align: left; margin-bottom: 10px">
                  {{ data.venueName }}{{ typeText }}
                </p>
                <!-- <p style="text-align: center;">羽毛球场地预约</p> -->
                <div style="overflow: hidden">
                  <div class="item">
                    <div>数量</div>
                    <div class="info title-number">
                      x {{ !isTicket ? number : data.ticketOutputList[0].qty }}
                    </div>
                  </div>
                  <template v-if="!isTicket">
                    <div class="venue-wrapper">
                      <div
                        class="venue-order-wrap"
                        v-for="(item, index) in data.spaceOutputList"
                        :key="index"
                      >
                        <div class="name-text">{{ item.spaceName }}</div>
                        <div class="content-left">
                          <div
                            class="statement-detail time-wrap"
                            v-for="(itemS, indexS) in item.detailOutputList"
                            :key="indexS"
                          >
                            <!-- <div>{{itemS.timeSlot}}</div>
                              <div>￥{{itemS.price}}元</div> -->
                            <div class="order-time-text">
                              {{ itemS.timeSlot }}
                              <span class="right">¥{{ itemS.price }}元</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="item" v-else>
                    <div>消费时段</div>
                    <div
                      v-for="itemS in data.ticketOutputList"
                      :key="`${itemS}`"
                      style="float: right; margin: 5px 10px 5px 10px"
                    >
                      <div>{{ itemS.startTime }}~{{ itemS.endTime }}</div>
                      <div>￥{{ itemS.price }}元</div>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="item">
                    <div>合计</div>
                    <div class="bottom-right">{{ data.totalPrice }}</div>
                  </div>
                  <div class="item">
                    <div>待支付</div>
                    <div class="bottom-right">{{ data.toBePaidPrice }}</div>
                  </div>
                  <div class="codeImg">
                    <img
                      class="image"
                      :src="require('@/assets/images/er_code.jpg')"
                      alt=""
                    />
                    <!-- <p>支付</p> -->
                    <p>扫码收藏店铺，随时可预约</p>
                    <p>谢谢光临，欢迎再来!</p>
                  </div>
                </div>
              </div>
              <div class="order-pay-right">
                <div class="codeImg">
                  <p class="text-name" style="text-align: center">请扫码支付</p>
                  <img :src="paymentQRCode" alt="" />
                  <!-- <p>扫码收藏店铺</p> -->
                  <!-- <p>谢谢光临，欢迎再来</p> -->
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="现金">
            <el-form ref="form" label-width="80px">
                <el-form-item label="实收">
                    <el-input clearable class="radius-input" v-model="form.reallyPrice" placehoider="输入收银金额">
                      <p slot="suffix">元</p>
                      <p slot="prefix">¥</p>
                    </el-input>
                    <el-button round class="sell">结算</el-button>
                  </el-form-item>
            </el-form>
          </el-tab-pane> -->
        </el-tabs>
      </el-container>
      <template slot="footer">
        <!-- <el-button @click="sureEvent">{{$t('sure')}}</el-button> -->
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isTicket: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    typeText: {
      required: false,
      type: String,
      default: "预约",
    },
  },
  data() {
    return {
      depts: [],
      data: {},
      paymentQRCode: "",
      form: {},
      number: "",
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.closeDialog();
      // 调用关闭方法，不要再写一次
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data, type, list) {
      // 在这里获取回显的数据
      this.number = this.isTicket ? 999 : list.length;
      this.$http
        .post(
          type === "1"
            ? apis.tic_ReservateOrderById
            : apis.getReservateOrderById,
          { id: data },
        )
        .then((res) => {
          this.data = res.data.rows;
          this.paymentQRCode = res.data.rows.paymentQRCode;
          // const wsServer = `ws://192.168.10.36:8004/websocket/${this.data.orderNo}`
          const wsServer = window.location.protocol === "http:"
              ? `ws://${window.location.host}:8004/websocket/${this.data.orderNo}`
              : `wss://sportscity.one-sports.com/payapi/websocket/${this.data.orderNo}`;
          const websocket = new WebSocket(wsServer); // 创建WebSocket对象
          websocket.onmessage = (evt) => {
            const data1 = JSON.parse(evt.data);
            if (data1.type === "pcreservate") {
              if (data1.code === "0") {
                this.$emit("closeDialog");
                this.$emit("refesh");
                this.$message.success("支付成功");
              }
            }
          };
        });
    },
    // 获取层级
    getPermissions() {
      this.depts = [
        {
          label: "无",
          value: "0",
        },
      ];
      this.$http.get(apis.dept_list).then((res) => {
        this.buildtree(res.data.rows, this.depts, 0);
      });
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId,
            value: item.deptId.toString(),
            label: item.deptName,
            children: [],
          };
          this.buildtree(list, child.children, item.deptId);
          arr.push(child);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pay-wrap {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin: 0 auto;
  width: 50%;
  border-radius: 20px;
  .content {
    h4 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
.item {
  overflow: hidden;
  div {
    display: inline-block;
    // margin: 5px 10px 5px 30px;
    text-align: left;
  }
  .info {
    float: right;
    margin-right: 40px;
  }
}
.bottom {
  margin-top: 30px;
  .codeImg {
    text-align: center;
  }
}
.sell {
  float: right;
  width: 90px;
  height: 30px;
  line-height: 4px;
  color: #fff;
  background: #439bff;
  margin-top: 20px;
}
.venue-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 170px;
  overflow: auto;
}
.venue-order-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .name-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(148, 152, 158, 1);
    // text-indent: 15px;
    line-height: 17px;
    width: 90px;
  }
  .content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .time-wrap {
    .order-time-text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(148, 152, 158, 1);
      // text-indent: 15px;
      line-height: 17px;
      margin-bottom: 6px;
      .right {
        margin-left: 12px;
      }
    }
  }
}
.order-wrapper {
  width: 100%;
}
.order-pay-content {
  display: flex;
  .right {
    width: 100%;
    height: 100%;
  }
}
.order-pay-right {
  background: #fff;
  display: flex;
  height: 638px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(228, 233, 241, 1);
}
.pay-wrap {
  padding: 20px 15px;
  border: 1px solid rgba(228, 233, 241, 1);
  background: #fff;
  // margin: 0 auto;
  // width: 50%;
  width: 300px;
  border-radius: 4px;
  position: relative;
  .pay-title {
    font-weight: 400;
    color: rgba(36, 45, 65, 1);
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  .pay-detail {
    width: 80px;
    margin: 0 auto;
    background: #fff;
    font-weight: 400;
    color: rgba(36, 45, 65, 1);
    font-size: 12px;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
  }
  .pay-detail-line {
    position: absolute;
    top: 69px;
    background: #e4e9f1;
    content: "";
    height: 1px;
    // width: 100%;
    left: 15px;
    right: 15px;
    z-index: 1;
  }
  .content {
    h4 {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
.line-wrapper {
  position: relative;
  .pay-statement {
    width: 80px;
    margin: 0 auto;
    background: #fff;
    font-weight: 400;
    color: rgba(36, 45, 65, 1);
    font-size: 12px;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
  }
  .pay-statement-line {
    position: absolute;
    top: 8px;
    background: #e4e9f1;
    content: "";
    height: 1px;
    // width: 100%;
    left: 15px;
    right: 15px;
    z-index: 1;
  }
}
.item {
  overflow: hidden;
  display: flex;
  flex: row;
  .title-number {
    text-align: right;
  }
  .statement-detail {
    display: flex;
    align-items: center;
    .price {
      text-align: right;
    }
  }
  div {
    font-weight: 400;
    color: rgba(36, 45, 65, 1);
    display: inline-block;
    text-align: left;
    font-size: 12px;
    margin-bottom: 16px;
    width: 200px;
  }
  .info {
    width: 100%;
    color: #242d41;
  }
  .info {
    float: right;
    // margin-right: 40px;
  }
}
.bottom {
  border-top: 1px solid #e4e9f1;
  padding-top: 25px;
  .bottom-right {
    text-align: right;
    color: rgba(210, 81, 75, 1);
  }
}
.codeImg {
  text-align: center;
  margin-top: 52px;
  .text-name {
    font-weight: 400;
    color: rgba(36, 45, 65, 1);
    font-size: 16px;
    margin-bottom: -15px;
    position: relative;
  }
  .image {
    width: 152px;
    height: 156px;
  }
}
.sell {
  float: right;
  width: 90px;
  height: 30px;
  line-height: 4px;
  color: #fff;
  background: #439bff;
  margin-top: 20px;
}
</style>
<style lang="less">
.order-pay-wrapper {
  .el-dialog--center {
    .el-dialog__body {
      background: #f8f9fa !important;
    }
  }
  .el-tabs__header {
    margin-left: 20px !important;
  }
  .el-tabs__item {
    padding: 0 57px !important;
  }
  .taps-card el-tabs {
    width: 100% !important;
  }
  .el-tabs--top {
    width: 100% !important;
  }
  .el-tabs__content {
    padding-bottom: 20px !important;
  }
  .el-tabs__active-bar {
    height: 0 !important;
  }
}
</style>
