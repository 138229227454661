import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
    zh,
    en,
}
class RemarkFrom {
    constructor(type, lanuage = 0) {
        if (type === 'form') {
            this.id = ''
            this.remark = ''
        } else if (type === 'rule') {
            this.remark = [
                {
                    required: true,
                    message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_remarkInfo,
                    trigger: 'blur',
                },
            ]
        }
    }
}
export default RemarkFrom
