<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="90px">
        <div class="tabs-container">
          <el-tabs
            class="taps-card"
            v-model="form.orderType"
            @tab-click="searchEvent"
          >
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="预约" name="1"></el-tab-pane>
            <el-tab-pane label="购票" name="2"></el-tab-pane>
            <el-tab-pane label="课程" name="3"></el-tab-pane>
            <el-tab-pane label="赛事" name="4"></el-tab-pane>
            <el-tab-pane label="会员卡" name="6"></el-tab-pane>
            <el-tab-pane label="活动" name="7"></el-tab-pane>
            <!-- 5---视频课程 -->
          </el-tabs>
        </div>
        <el-row>
          <el-col :span="18">
            <el-row>
              <el-col :span="8">
                <el-form-item label="订单号：">
                  <el-input
                    clearable
                    placeholder="请输入订单号"
                    v-model="form.orderNo"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户姓名：">
                  <el-input
                    clearable
                    placeholder="请输入客户姓名"
                    v-model="form.searchCon"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下单时间：">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="range"
                    type="daterange"
                    format="yyyy - MM - dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="场馆：">
                  <el-input
                    clearable
                    placeholder="请输入场馆名称"
                    v-model="form.venueName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="运动项目：">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.sportsProjectCode"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in DROPDOWNBOX.VENUE_MAIN_DIRECTION"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单来源：">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.orderFrom"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in orderList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="支付类型：">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.payType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in payTypeList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row type="flex" justify="end">
              <el-button @click="cleanForm" class="clean-button">{{
                $t("reset")
              }}</el-button>
              <el-button @click="searchEvent" class="search-button">{{
                $t("search")
              }}</el-button>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="search-statue">
          <div class="orderSpan">订单状态</div>
          <div
            style="display: inline-block; margin: 0 10px"
            v-for="(item, index) in statusData"
            :key="index"
          >
            <el-button
              style="border: 1px solid #439bff; background: #439bff"
              :class="{ unchose: form.orderStatusText !== item }"
              @click="tabClick(index)"
              round
              >{{ item }}</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        :key="form.orderType === '1' ? '预约' : '购票'"
      >
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="200"
        ></el-table-column>
        <el-table-column
          prop="customNam"
          align="center"
          label="客户姓名"
          min-width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="customPhone"
          label="联系电话"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="merchantAccountName"
          align="center"
          label="商户"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="场馆"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="运动项目"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          align="center"
          label="订单类型"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          min-width="100"
          label="订单金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          min-width="120"
          label="实付金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="settlementRateAmount"
          min-width="100"
          label="费率"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="settlementAmount"
          min-width="100"
          label="结算金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          label="下单时间"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="left" min-width="300" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="changeAddDialog(true, true, scope.row)"
              >详情</el-button
            >
            <el-button
              type="text"
              @click="
                openRemarkDialog({
                  ...scope.row,
                })
              "
              >备注</el-button
            >
            <el-button
              type="text"
              :disabled="
                scope.row.returnFlag === 'N'
              "
              @click="openRefundDialog(scope.row.id)"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="sumClass">
      <div class="left">
        <span class="orderNum">总计{{ countNum }}个订单</span>
      </div>
      <div class="page-wrapper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 备注弹窗 -->
    <!-- 备注弹窗 -->
    <remark-dialog
      ref="confirmDialog"
      :id="remarkDialog.remarkId"
      :Language="Language"
      :show="remarkDialog.visible"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></remark-dialog>
    <!-- 确认退款弹窗 -->
    <confirm-dialog
      :sureDisabled="confirmDialog.sureDisabled"
      ref="confirmDialog"
      :text="confirmDialog.text"
      :Language="Language"
      :show="confirmDialog.visible"
      @sureDialog="onConfirmDialog"
      @closeDialog="closeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import remarkDialog from "./dialog/remarkDialog.vue";
import OrderRecordSearchClass from "./orderRecordSearchClass";
import confirmDialog from "@/components/confirmDialogNew.vue";
import addDialog from "./dialog/addNewDialog.vue";

export default {
  components: {
    remarkDialog,
    confirmDialog,
    addDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new OrderRecordSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      pictLoading: false,
      addDialog: {
        visible: false,
        isEdit: false,
      },
      statusData: ["全部", "待支付", "已支付", "已取消", "已退款"],
      range: [],
      remarkDialog: {
        visible: false,
        remarkId: "",
      },
      countNum: 0,
      countAllPrice: 0,
      confirmDialog: {
        visible: false,
        text: "",
        data: {},
        sureDisabled: false,
      },
      detailDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      sportList: [],
      orderList: [
        {
          lable: "全部",
          value: "",
        },
        {
          lable: "线上-小程序",
          value: "mp",
        },
        {
          lable: "线下-现场开单",
          value: "venue",
        },
      ],
      payTypeList: [
        {
          lable: "微信",
          value: "1",
        },
        {
          lable: "线下-现金",
          value: "2",
        },
        {
          lable: "会员卡",
          value: "3",
        },
        {
          lable: "线下-支付宝",
          value: "4",
        },
        {
          lable: "线下-微信",
          value: "5",
        },
        {
          lable: "线下-刷卡",
          value: "6",
        },
      ],
    };
  },
  filters: {
    payTypeFilter(status) {
      const statusMap = {
        1: "微信",
        5: "线下-微信",
        3: "会员卡付",
        4: "线下-支付宝",
        2: "线下-现金",
        6: "线下-刷卡",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "Language", "venueId", "merchantAccountDetailInfoDTO"]),
  },
  activated() {
    // if (this.$route.query.venueId) {
    //   this.form.finalVenueId = this.$route.query.venueId;
    //   this.form.orderStatusText = "已支付";
    //   this.form.searchStatusType = "2";
    //   this.getSportList(this.form.finalVenueId);
    // } else {
    //   this.getSportList(this.venueId);
    //   this.form.finalVenueId = this.venueId;
    // }
    // if (this.$route.query.type) {
    //   this.form.orderType = this.$route.query.type;
    // }
    // this.form.orderFrom = this.$route.query.orderFrom;
    // this.form.payType = this.$route.query.payType;
    // if (this.$route.query.date) {
    //   this.range = JSON.parse(this.$route.query.date);
    // }
    this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
  },
  mounted() {},
  methods: {
    getSportList(id) {
      this.$http
        .get(`${apis.getSportsProjectListById}?id=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    closeConfirmDialog(dialogStatus) {
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
      }
    },
    openRefundDialog(id) {
      this.confirmDialog.data.id = id;
      this.confirmDialog.text = "您确认要退款操作吗";
      this.onRefundDialog(true);
    },
    onRefundDialog(dialogStatus) {
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.id = "";
        this.searchEvent();
      }
    },
    onConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.confirmDialog.sureDisabled = true;
      this.$http
        .post(apis.ordermanagementRefund, {
          id: this.confirmDialog.data.id,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
            const loading = this.$loading({
              lock: false,
              text: "正在加载中.....",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
              customClass: "loading-login",
            });
            this.timer = setTimeout(() => {
              this.$message.success("提交退款，退款进行中!");
              this.searchData();
              loading.close();
            }, 5000);
            this.confirmDialog.sureDisabled = false;
          } else {
            this.confirmDialog.sureDisabled = false;
          }
        });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      console.log(123);
      if (this.range.length) {
        [this.form.orderStartTime, this.form.orderEndTime] = this.range;
      }
      this.pictLoading = true;
      this.tableData = [];
      this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
      console.log(this.form);
      this.$http
        .post(apis.queryOrderManagementListWithSettlementInfo, this.form)
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.countNum = res.data.total;
            this.form.total = res.data.total;
            this.pictLoading = false;
            console.log(res.data);
          } else if (res.data.code === 500) {
            this.tableData = [];
            this.form.total = 0;
            this.countNum = 0;
          }
        });
    },
    /**
     * @function 表格按照价格排序
     */
    sortMoney(a, b, item) {
      return parseFloat(a[item]) - parseFloat(b[item]);
    },
    sortPayTime(a, b, item) {
      return new Date(a[item]).getTime() - new Date(b[item]).getTime();
    },
    /**
     * @function tab切换
     */
    tabClick(index) {
      if (index === 0) {
        this.form.searchStatusType = "";
      } else if (index === 1) {
        this.form.searchStatusType = "1";
      } else if (index === 2) {
        this.form.searchStatusType = "2";
      } else if (index === 3) {
        this.form.searchStatusType = "3";
      } else {
        this.form.searchStatusType = "4";
      }
      this.form.orderStatusText = this.statusData[index];
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new OrderRecordSearchClass("form");
      this.range = "";
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        this.$refs["addDialog"].getDataQuery(data, isEdit);
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        // this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      this.searchData();
    },
    /**
     * @function 修改备注弹窗
     * @param {Array} data 要重置密码的值
     */
    openRemarkDialog(data, isEdit = false) {
      this.remarkDialog.remarkId = data.id;
      this.remarkDialog.visible = true;
      this.$refs.confirmDialog.getDataQuery(data, isEdit);
    },
    /**
     * @function 改变备注弹窗的状态
     */
    changeConfirmDialog() {
      this.remarkDialog.visible = false;
    },
    /**
     * @function 改变详情弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} data 打开详情，传递数据
     */
    changeDetailDialog(dialogStatus, data = {}) {
      this.detailDialog.data = data;
      this.detailDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.detailDialog.data.ids = "";
        this.searchEvent();
      } else {
        this.$refs["detailDialog"].getDataQuery(data);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.tabs-container {
  margin: 0 20px;
}
.unchose {
  background: #fff !important;
  color: #439bff !important;
}
.sumClass {
  background: rgba(238, 240, 244, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.orderSpan {
  display: inline-block;
  color: #606266;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #c0c4cc !important;
}
.search-container {
  padding: 5px 31px 5px 5px;
  width: calc(100% - 15px)!important;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
.button-container {
  text-align: left;
}
.table-container {
  width: calc(100% - 30px);
  margin: 0px auto;
  thead {
    tr {
      th {
        background: #eef0f4 !important;
      }
    }
  }
}
.expand .el-table__expand-column .cell {
  display: none;
}
</style>
